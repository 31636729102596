import { ref } from "vue";
export const trumbowygEditor = ref(null);
export const pageSelectRef = ref(null);
export const cropImgFlag = ref(false);
export const blockOverlayActive = ref(false);
export const show = ref("general")

export const toggleBlockOverlay = (newValue) => {
  blockOverlayActive.value = newValue;
};
