<template>
  <div id="main" class="root page-wrapper">
    <div class="page-body-wrapper">
      <GeneralEdit ref="edit" :show="show" :cliente="cliente" :folder="cliente.Carpeta_imagenes"
        @colorChanged="onColorChanged" @modalSave="abrirModalGuardar" @uploadImage="onuploadImage" @changeFont="onChangeFont" />
      <GeneralView :cliente="cliente" :timeStamp="timeStamp" @clicked="onClickChild" @publish="abrirModal" @vpublicada="cargarVPublicada" />
      <CropperImg :showCropper="cropImgFlag" :loadedImg="cropImg" :width="cropWidth" :height="cropHeight" :minContainerHeight="cropMinContainerHeight" :minCanvasWidth="cropMinCanvasWidth" :ratio="cropRatio" @cropImage="oncropImage" @close ="closeCropModal"/>
    </div>

    <div id="modal" class="modal-cms" :class="{ modalActive: showModal != '' }" tabindex="-1" aria-labelledby="modalTit"
      aria-hidden="true">
      <div class="modalDiv">
        <div class="modalCloseDiv">
          <i class="fa-solid fa-xmark modalCloseBtn" v-on:click="cerrarModal"></i>
        </div>
        <div class="modalIconDiv">
          <i class="fa-regular fa-circle-check modalIcon" style="color:#67dc53" v-if="showModal == 'ok'"></i>
          <i class="fa-regular fa-circle-xmark modalIcon" style="color:#e31c3d" v-if="showModal == 'nok'"></i>
        </div>
        <div class="modalTextDiv pe-none">
          <p v-if="showModal == 'ok'">
            ¡Tus cambios han sido realizados con éxito!
          </p>
          <p v-if="showModal == 'nok'">
            No se han podido realizar tus cambios,
            vuelve a intentarlo
          </p>
          <p v-if="showModal == 'save'">
            ¿Seguro que quieres guardar los cambios?
          </p>
          <p v-if="showModal == 'publish'">
            ¿Seguro que quieres publicar la web?
          </p>
        </div>
        <div class="modalBtnDiv">
          <button class="btn btn-outline-primary" v-on:click="cerrarModal" v-if="showModal == 'ok' || showModal == 'nok'">OK</button>
          <button class="btn btn-outline-primary" v-on:click="guardarCambios" v-if="showModal == 'save'">Si, guardar cambios</button>
          <button class="btn btn-primary" v-on:click="cerrarModal" v-if="showModal == 'save'">No guardar</button>
          <button class="btn btn-outline-primary" v-on:click="publicarCambios" v-if="showModal == 'publish'">Si, enviar</button>
          <button class="btn btn-primary" v-on:click="cerrarModal" v-if="showModal == 'publish'">No</button>
        </div>
      </div>
    </div>
    <div id="loadingmodal" class="modal-loading" :class="{ modalActive: showLoading != '' }">
      <div class="modalDiv">
        <div class="loadingDiv">
          <p>
            <span v-if="showLoading == 'get'">Cargando Datos</span>
            <span v-if="showLoading == 'save'">Guardando cambios</span>
            <span v-if="showLoading == 'publish'">Publicando cambios</span>
          </p>
          <div class="loader-box">
            <div class="dot-pulse dot-a"></div>
            <div class="dot-pulse dot-b"></div>
            <div class="dot-pulse dot-c"></div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showLoading === ''">  
      <ToursWidget />
      <BlockOverlay />
    </template>
  </div>
</template>

<script class="notranslate">
import GeneralEdit from './components/GeneralEdit.vue'
import GeneralView from './components/GeneralView.vue'
import CropperImg from './components/CropperImg.vue'
import ToursWidget from './components/ToursWidget.vue'
import BlockOverlay from './components/BlockOverlay.vue';
import { useToast } from "vue-toastification";
import axios from "axios"
import { cropImgFlag, show } from './sharedState';

export default {
  name: 'MyHome',
  components: {
    GeneralEdit,
    GeneralView,
    CropperImg,
    ToursWidget,
    BlockOverlay
  },
  mounted() {
    this.dominio = history.state.dominio;
    this.user_hash = history.state.user_hash;
    this.cliente.urlImg = process.env.VUE_APP_IMG_LINK_SAVE;
    this.getCliente();
  },
  data() {
    return {
      user_hash: "",
      dominio: "",
      showModal: '',
      showLoading: '',
      cropImg: "",
      cropWidth: 0,
      cropHeight: 0,
      cropMinContainerHeight: 400,
      cropMinCanvasWidth: 120,
      cropRatio: 1/1,
      timeStamp: Date.now(),
      cliente: {
        Datos_json: {
          Color: "#4f8cf0",
          Servicios: [{ Titulo: '', Descripcion: '', Img: '' },],
        },
        reviews: [{ COMMENT: '', name_reviewer: '' },]
      },
      clienteGuardado: {}
    }
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast, cropImgFlag, show }
  },
  methods: {
    toogleSidebar(){
      let etgle = document.querySelector("#toogleSidebar");
      let emain = document.querySelector("#main");
      etgle.onclick = function(){
        if (emain.classList.contains("sow-sidebar")) {
          emain.classList.remove('sow-sidebar');
        } else {
          emain.classList.add('sow-sidebar');
        }        
      };
    },
    changeFavicon() {
      // Get or create the favicon
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      // Set the attributes of the favicon
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.cliente.urlImg + '/' + this.cliente.Carpeta_imagenes + '/' + this.cliente.Datos_json.FavIcon;
      // Append the favicon to the `head`
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    onChangeFont() {
        let flink = document.querySelector("#fontsite");
        flink.href = 'https://fonts.googleapis.com/css2?family=' + this.cliente.Datos_json.Fuente.replace(" ", "+") + ':wght@400;500;600;700&display=swap';
    },
    async getCliente() {
      try {
        this.mostrarLoading('get');
        let url = process.env.VUE_APP_API + "obtener_datos_cms";
        let body = { "client_domain": this.dominio, "user_hash": this.user_hash };
        let headers = {
          headers: {
            'Authorization': process.env.VUE_APP_APIKEY
          }
        };
        let response = await this.post(url, body, headers)
        this.mostrarLoading('');      
        if (response.status == 200 && response.data.Cliente_id) {
          let telephone = response.data.Telefono, formatTel = telephone;
          this.cliente.Tiene_chatbot = response.data.Tiene_chatbot;
          this.cliente.Cliente_id = response.data.Cliente_id;
          this.cliente.Datos_json = response.data.Datos_json_guardado;
          this.cliente.Datos_json_publicado = response.data.Datos_json_publicado;
          this.cliente.Carpeta_imagenes = response.data.Carpeta_imagenes;
          this.cliente.Direccion = response.data.Direccion;
          this.cliente.Calle = response.data.Calle;
          this.cliente.Numero_calle = response.data.Numero_calle;
          this.cliente.Codigo_postal = response.data.Codigo_postal;
          this.cliente.Ciudad = response.data.Ciudad;
          this.cliente.Provincia = response.data.Provincia;
          this.cliente.MapaGoogle = response.data.MapaGoogle;
          this.cliente.Telefono = formatTel.replace(/\W/gi, '').replace(/(.{3})/g, '$1 ');
          this.cliente.Mail = response.data.Mail;
          this.cliente.Nombre = response.data.Nombre;
          this.cliente.RegistroSanitario = response.data.RegistroSanitario;
          this.cliente.reviews = response.data.reviews;
          this.cliente.Horario = this.formatHorario(response.data.Horario);
          this.cliente.MatrizColores = response.data.Colores;
          this.cliente.Colores =  Object.keys(this.cliente.MatrizColores);
          this.cliente.Fuentes = response.data.Fuentes;
          if(!this.cliente.Datos_json.Descripcion_sobre_nosotros_corta)
            this.cliente.Datos_json.Descripcion_sobre_nosotros_corta = this.cliente.Datos_json.Descripcion_sobre_nosotros;
          this.cliente.VersionGuardada = response.data.VersionGuardada;
          // cargamos el favicon del cliente
          this.changeFavicon();
          // cerramos si es necesario el tooglebar
          this.toogleSidebar();
          // cargamos la fuente correspondiente del cliente
          this.onChangeFont();
          // Si el cliente tiene una version guardada mostramos esa
          if (this.cliente.VersionGuardada)
            this.clienteGuardado = this.cliente.Datos_json;
        } else {
          this.$router.push('/NotFound')
        }
        this.hexToRGb(this.cliente.Datos_json.Color);
        if (response.data.VersionGuardada)
          this.toast.info("Se ha cargado la versión previamente Guardada");
      } catch (error) {
        this.$router.push('/NotFound')
      }
    },
    onClickChild(value) {
      show.value = value;
    },
    /* COLOR */
    onColorChanged(value) {
      this.hexToRGb(value);
    },
    hexToRGb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);
      this.cliente.Color_rgb = r + ',' + g + ',' + b;
      this.cliente.Color_rgba = 'rgba(' + r + ',' + g + ',' + b + ',0.3)';
      this.cliente.Color_hover = 'rgb(' + (r - 45) + ',' + (g - 45) + ',' + (b - 50) + ')';
      this.cliente.Color_Font = this.cliente.MatrizColores[hex];
    },
    /* Imagenes */
    onuploadImage(img, width, height, minHeight, minWidth, ratio) {
      cropImgFlag.value = true;
      this.cropImg = img;
      this.cropWidth = width;
      this.cropHeight = height;
      this.cropMinContainerHeight = minHeight;
      this.cropMinCanvasWidth = minWidth;
      this.cropRatio = ratio;
    },
    oncropImage(img) {
      cropImgFlag.value = false;
      this.$refs.edit.showCropImg(img);
    },
    closeCropModal() {
      cropImgFlag.value = false;
      this.cropImg = '';
      this.$refs.edit.cleanImg();
    },
    /* HORARIO */
    formatHorario(horario) {
       var horario_final = 'L-';
      var horarios = [];
      try {
        this.aux = JSON.parse(horario);
        // variable para contar el dia de cerrados
        var cerrados = 0
        // Comprobamos que dias esta abierto
        for(var index in this.aux){
          var dia = this.aux[index];
          if(dia.abierto){
            // Formateamos el horario
            horarios.push(this.getHorario(dia.horario));
          }else{
            cerrados++;
            horarios.push('Cerrado');
          }
        }
        if(cerrados == horarios.length)
          return "";

        var flag = true;
        // Receorremos los días para darle formate html al horario
        // Empezamos el bucle por el 2 dia (Martes)
        for (let i = 1; i < horarios.length; i++) {
          // Vamos comprobando los días para ver si se corresponde con el primer y poder agruparlos todos EX (L-V 9-14)
          // Si hemos encontrado un dia distinto del primero (Lunes) marcamos los días en individual
          if(flag){
            if(horarios[i] == horarios[i-1] ){
              flag = true;
            }else{
              // Si es el dia > Martes
              if(i>1){
                horario_final+= this.aux[i-1].dia + ' ' + horarios[i-1]  + ' <br>'
                horario_final+= this.aux[i].dia + ' - ' + horarios[i] + ' <br>'
              }else{ // Si el martes ya no coincide, completamos el horario del lunes y metemos el del martes
                horario_final+= ' ' + horarios[i-1] + ' <br>'
                horario_final+= this.aux[i].dia + ' - ' + horarios[i] + ' <br>'
              }
              flag = false;
            }
          }else{
            horario_final+= this.aux[i].dia + ' - ' + horarios[i] + ' <br>'
          }
        }
        return horario_final;
      } catch (error) {
        console.log(error)
        return "";
      }
      

    },
    getHorario(time){
      var horario = time[0];
      var aux = '';
      var apertura = JSON.parse(horario.Horario_apertura);
      var mediodia = JSON.parse(horario.Horario_mediodia);
      aux+= apertura.Inicio + '-';
      if(mediodia){
        aux+= mediodia.Inicio+ ' '; 
        aux+= mediodia.Fin + '-';
      }
      aux+= apertura.Fin;
      return aux.replaceAll(':0','');
    },
    /* CAMBIOS */
    async publicarCambios() {
      if (window.send_analytics != undefined) {
        window.send_analytics("Click.Publicar");
      }
      this.cerrarModal();
      // llamada al metodo de publicación
      if (this.clienteGuardado.Color) {
        var response = await this.guardarDatos(true, this.clienteGuardado);
        if (response.status == 200) {
          this.toast.info("Datos publicados correctamente");
          this.getCliente();
        } else
          this.toast.error("Se ha producido un error al publicar los datos");
      } else {
        this.toast.error("No hay datos pendientes de publicar");
      }
      // Despues de la publicación devemos hacer una recarga de los datos
    },
    async guardarCambios() {
      this.cerrarModal();
      this.mostrarLoading('save');

      var guardadoImg = await this.$refs.edit.guardar();

      if (guardadoImg) {
        this.clienteGuardado = this.$refs.edit.passEditClient();
        var response = await this.guardarDatos(false, this.clienteGuardado);
        if (response.status == 200) {
          this.toast.info("Datos guardados correctamente");
          this.$refs.edit.cleanImg();
          // UPDATE TIMESTAMP
          this.timeStamp = Date.now();
        } else {
          this.mostrarLoading('');
          if(!response){
            this.toast.warning("El servicio no tenia imagen, se mantendra la imagen por defecto.");
          }else{
            this.toast.error("Se ha producido un error al guardar los datos");
          }
        }
      } else {
        this.mostrarLoading('');
        if(!response){
            this.toast.warning("El servicio no tenia imagen, se mantendra la imagen por defecto.");
          }else{
            this.toast.error("Se ha producido un error al guardar los datos");
          }
      }
    },
    /* MODAL */
    abrirModal(value) {
      this.showModal = value
    },
    cerrarModal() {
      this.showModal = ''
    },
    abrirModalGuardar() {
      this.abrirModal('save');
    },
    mostrarLoading(value) {
      this.showLoading = value;
    },
    cargarVPublicada() {
      if (window.send_analytics != undefined) {
        window.send_analytics("Click.CargarVPublicada");
      }
      this.toast.info("Se ha cargado la versión publicada actualmente");
      this.cliente.Datos_json = this.cliente.Datos_json_publicado;
      this.cliente.urlImg = process.env.VUE_APP_IMG_LINK_PUBLISH;
      this.cliente.VersionGuardada = 0;
      this.clienteGuardado = {};
      this.hexToRGb(this.cliente.Datos_json.Color);
    },
    /* GUARDAR CAMBIOS */
    async guardarDatos(publish, datos) {
      if (publish)
        this.mostrarLoading('publish');
      else
        this.mostrarLoading('save');

      let url = process.env.VUE_APP_API + "guardar_datos_web";
      let body = {
        "client_id": this.cliente.Cliente_id,
        "datos_json": datos,
        "folder_name": this.cliente.Carpeta_imagenes,
        "publish": publish
      };
      let headers = {
        headers: {
          'Authorization': process.env.VUE_APP_APIKEY
        }
      };
      let response = await this.post(url, body, headers)
      if (publish)
        this.delete_cookie('YODA_USER_ID', this.dominio)
      this.mostrarLoading('');
      return response;
    },
    delete_cookie(name, domain) {
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + domain + ';';
    },
    /* HTPP CALLS */
    async post(url, body, header) {
      let response = await axios.post(url, body, header);
      return response
    }
  }
}
</script>
<style>
.root {
  --main-sidebar-width: 380px;
  --main-header-height: 76px;
  --main-primary-color-rgb: 99, 98, 231;
  --main-primary-color: #6362e7;
  --main-primary-color-hover: #5655e5;
  --main-secondary-color: #e9eef5;
  --main-secondary-color-hover: #e2e8f0;
  --main-color-hexa: v-bind('cliente.Datos_json.Color');
  --main-color-rgb: v-bind('cliente.Color_rgb');
  --main-color-rgba: v-bind('cliente.Color_rgba');
  --main-color-button-hover: v-bind('cliente.Color_hover');
  --main-font: v-bind('cliente.Datos_json.Fuente');
  --main-color-contrast: v-bind('cliente.Color_Font');
}

body {
  font-size: 14px !important;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif !important;
  color: #1e2f65 !important;
}

.header-wrapper .btn-primary,
.sidebar-wrapper .btn-primary,
.modalBtnDiv .btn-primary,
.modal-cropper .btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--main-primary-color);
  --bs-btn-border-color: var(--main-primary-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--main-primary-color-hover);
  --bs-btn-hover-border-color: var(--main-primary-color-hover);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--main-primary-color-hover);
  --bs-btn-active-border-color: var(--main-primary-color-hover);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--main-primary-color);
  --bs-btn-disabled-border-color: var(--main-primary-color);
}

.header-wrapper .btn-outline-primary,
.sidebar-wrapper .btn-outline-primary,
.modalBtnDiv .btn-outline-primary,
.modal-cropper .btn-outline-primary {
  --bs-btn-color: var(--main-primary-color);
  --bs-btn-border-color: var(--main-primary-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--main-primary-color);
  --bs-btn-hover-border-color: var(--main-primary-color);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--main-primary-color);
  --bs-btn-active-border-color: var(--main-primary-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--main-primary-color);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--main-primary-color);
  --bs-gradient: none;
}

.btn {
  position: relative;
  border-radius: 50px !important;
  padding: 8px 40px !important;
  overflow: hidden;
  z-index: 1;
}

.form-label {
  font-weight: 500 !important;
  margin-bottom: 0.25rem !important;
}

.form-control,
.form-select {
  height: 50px !important;
  border-color: #c8c5da !important;
  border-radius: 0.5rem !important;
}

textarea.form-control {
  min-height: 160px !important;
}

.header-wrapper .form-select {
  width: 180px;
  height: 40px !important;
}

.page-wrapper {
  position: relative;
}

.page-wrapper .page-header {
  max-width: 100vw;
  position: fixed;
  top: 0;
  z-index: 230;
  -webkit-transition: .5s;
  transition: .5s;
  background-color: #fff;
  height: var(--main-header-height);
  margin-left: var(--main-sidebar-width);
  width: calc(100% - var(--main-sidebar-width));
  box-shadow: 0 5px 25px 0 rgb(89 102 122 / 15%);
}

.page-wrapper .page-header .header-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 13px 30px;
  position: relative;
  background-color: #fff;
  transition: all .2s ease-in-out;
}

.page-wrapper .page-header .header-wrapper .btn {
  padding: 6px 30px !important;
  font-weight: 500 !important;
  font-size: 1.08rem !important;
}

.page-wrapper .page-body-wrapper {
  background-color: #f4f6fd;
}

.page-wrapper .page-body-wrapper .sidebar-wrapper {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 250;
  height: auto;
  line-height: inherit;
  background: #fff;
  width: var(--main-sidebar-width);
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 5px 0 25px 0 rgb(89 102 122 / 15%);
  box-shadow: 5px 0 25px 0 rgb(89 102 122 / 15%);
  height: 100vh;
}

.page-wrapper .page-body-wrapper .sidebar-main {
  width: 100%;
  height: calc(100% - 116px);
  padding: 30px 20px;
  position: relative;
  overflow-y: overlay;
}

.page-wrapper .page-body-wrapper .sidebar-footer {
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
    min-height: 145px;
    border-top: 1px solid #ebebeb;
}

.page-wrapper .page-body-wrapper .sidebar-main::-webkit-scrollbar {
  width: 8px;
  /* Tamaño del scroll en vertical */
  height: 8px;
  /* Tamaño del scroll en horizontal */
}

.page-wrapper .page-body-wrapper .sidebar-main::-webkit-scrollbar-thumb {
  background: rgb(201 202 219 / 65%);
  border-radius: 4px;
}

.page-wrapper .page-body-wrapper .sidebar-main::-webkit-scrollbar-thumb:hover {
  background: rgb(201 202 219 / 85%);
  box-shadow: none;
}

.page-wrapper .page-body-wrapper .sidebar-main::-webkit-scrollbar-thumb:active {
  background-color: rgb(201 202 219 / 98%);
}

.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - var(--main-header-height));
  margin-top: var(--main-header-height);
  margin-left: var(--main-sidebar-width);
  padding: 35px 15px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper {
  padding: 5px 40px;
  border-bottom: 1px solid #ebebeb;
}

.modal-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 1000;
  display: none;
}
.modal-cropper, .modal-cms {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.85);
  z-index: 1055;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modalActive {
  display: flex !important;
}
.modal-cropper .modal-content {
    color: #1e2f65;
    background-color: #fff;
    border: none;
    border-radius: 0.5rem;
}
.modal-cropper .modal-body {
    padding: 1rem;
}
.modal-cropper .modal-footer {
    gap: 0.5rem;
    padding: calc(1rem - 0.5rem * .5);
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
}

.modalDiv {
  width: 450px;
  margin: auto;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
}

.modalDiv p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
}

.modalTextDiv {
  margin-top: 7%;
  margin-bottom: 7%;
}

.modalBtnDiv {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 60%;
  margin: 10px auto;
}

.modalIconDiv {
  margin-top: 20px;
}

.modalIcon {
  font-size: 75px;
}

.modalCloseDiv {
  display: flex;
  justify-content: flex-end;
}

.modalCloseBtn {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    padding: 5px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.modalCloseBtn:hover {
  color:var(--main-primary-color);
}

.loadingDiv {
  padding: 10%;
  font-size: 20px;
  text-align: center;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: var(--main-primary-color) !important;
    background-color: rgba(var(--main-primary-color-rgb), 0.10) !important;
}

/* Trumbowyg Box */
.trumbowyg-box, .trumbowyg-editor {
    border-color: #c8c5da !important;
    border-radius: 0.5rem !important;
    min-height: 220px !important;
}
.trumbowyg-editor, .trumbowyg-textarea {
    min-height: 180px !important;
}
.trumbowyg-button-pane {
    background: #efeffd !important;
    border-bottom-color: #c8c5da !important;
    border-radius: 0.45rem 0.45rem 0 0 !important;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.loader-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.dot-pulse {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  color: inherit;
  vertical-align: middle;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: var(--main-primary-color);
  opacity: 0.65;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: dotPulse 2s infinite ease-in-out;
  animation: dotPulse 2s infinite ease-in-out;
}

.dot-pulse::after {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@keyframes dotPulse {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

/* RESPONSIVE */
@media only screen and (min-width: 1420px) {
  .root {
    --main-sidebar-width:380px !important;
  }
  .wrap-tooglesidebar {
      gap: 0;
  }
  .toogle-sidebar {
      display: none !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1320px) and (max-width: 1419px) {
  .root {
    --main-sidebar-width:315px;
  }
  .page-wrapper .page-body-wrapper .page-body {
      margin-left: var(--main-sidebar-width);
  }
  .wrap-tooglesidebar {
      gap: 0;
  }
  .toogle-sidebar {
      display: none !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 680px)  and (max-width: 1319px) {
  .root {
    --main-sidebar-width:300px;
  }
  .page-wrapper .page-body-wrapper .page-body {
      margin-left: 0px !important;
  }
  .page-wrapper .page-body-wrapper .sidebar-wrapper {
      transform: translate(-340px, 0px);
  }
  .page-wrapper .page-header {
      margin-left: 0;
      width: 100%;
  }
  .wrap-tooglesidebar {
      gap: 0.85rem;
  }
  .toogle-sidebar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
  }
  .toogle-sidebar i {
      font-size: 24px;
  }
  .sow-sidebar.page-wrapper .page-body-wrapper .sidebar-wrapper {
      transform: translate(0px, 0px);
  }
  .sow-sidebar.page-wrapper .page-header .header-wrapper {
      margin-left: var(--main-sidebar-width) !important;
      width: calc(100% - var(--main-sidebar-width));
      padding-left: 10px !important;
  }
}


#google_translate_element, #goog-gt-tt, .goog-te-banner-frame, .skiptranslate {
    display: none !important;
    position: absolute;
    top: -500px;
    z-index: 0;
}

.sidebar-wrapper font, .sidebar-wrapper font > font,
.page-header font, .page-header font > font {
  background-color: transparent !important;
  box-shadow: none !important;
}

.translated-ltr, .translated-rtl {
  height:auto !important; 
}
body {
  top:0px !important;
}
</style>